// src/ExperiencePage.js
import React from 'react';
import Experience from './Experience';
import experience1 from './assets/images/amazon.png';
import experience2 from './assets/images/gdsc.png';

export const experiencesData = [
  {
    id: 2,
    title: 'SDE Intern',
    company: 'Amazon Supply Chain Optimization Technologies (SCOT)',
    imageSrc: experience1,
    period: 'May 2024 - Aug 2024',
    description: 'Returning as an SDE Intern under SCOT for Summer 2024.'
  },
  {
    id: 1,
    title: 'CFO',
    company: 'Google Developer Student Club (CMU)',
    imageSrc: experience2,
    period: 'Aug 2023 - Present',
    description: 'Manage and coordinate club finances, pursue financial opportunities, and develop software solutions for local businesses.'
  },
  {
    id: 0,
    title: 'SDE Intern',
    company: 'Amazon Supply Chain Optimization Technologies (SCOT)',
    imageSrc: experience1,
    period: 'May 2023 - Aug 2023',
    description: '12-week software development internship within Amazon SCOT. Focused on Java and software design.',
  },
];

const ExperiencePage = () => {
  return (
    <div className="max-w-4xl mx-auto mt-10">
      <h1 className="text-4xl font-bold text-center mb-10">Work Experience</h1>
      {experiencesData.map(exp => (
        <Experience key={exp.id} {...exp} />
      ))}
    </div>
  );
};

export default ExperiencePage;
