import React, { useState, useEffect } from 'react';
import Project from './Project';
import cvproject1 from './assets/images/cvproject1.png';

export const categoriesData = {
  'Computer Vision': [
    {
      name: 'Edge Detect',
      href: '/computer-vision#edge-detect',
      highlight: true,
      imageSrc: cvproject1,
      description: 'Description of the Edge Detect project and how it was significant.'
    },
    // Removed Project 2 as it's no longer mentioned
  ],
  '15-213: Introduction to Computer Systems': [
    {
      name: 'Cache Lab',
      href: '/intro-to-computer-systems#cache-lab',
      highlight: false,
      imageSrc: '/assets/images/cache-lab.png', // Placeholder, update with actual path
      description: 'Description of the Cache Lab project.'
    },
    {
      name: 'Malloc',
      href: '/intro-to-computer-systems#malloc',
      highlight: false,
      imageSrc: '/assets/images/malloc.png', // Placeholder, update with actual path
      description: 'Description of the Malloc project.'
    },
    {
      name: 'Shell',
      href: '/intro-to-computer-systems#shell',
      highlight: false,
      imageSrc: '/assets/images/shell.png', // Placeholder, update with actual path
      description: 'Description of the Shell project.'
    },
    {
      name: 'Proxy',
      href: '/intro-to-computer-systems#proxy',
      highlight: false,
      imageSrc: '/assets/images/proxy.png', // Placeholder, update with actual path
      description: 'Description of the Proxy project.'
    },
  ],
  '17-214: Software Construction': [
    {
      name: 'Santorini',
      href: '/software-construction#santorini',
      highlight: true,
      imageSrc: '/assets/images/santorini.png', // Placeholder, update with actual path
      description: 'Description of the Santorini project in the Software Construction course.'
    },
  ],
  'Hackathons': [
    {
      name: 'MindScape at CalHacks 10.0',
      href: '/hackathons#mindscape-calhacks',
      highlight: false,
      imageSrc: '/assets/images/mindscape.png', // Assuming this is the correct image
      description: 'Description of MindScape developed during CalHacks 10.0.'
    },
    {
      name: 'Freight Guard at TartanHacks 2024',
      href: '/hackathons#freight-guard',
      highlight: false,
      imageSrc: '/assets/images/freight-guard.png', // Placeholder, update with actual path
      description: 'Description of Freight Guard developed during TartanHacks 2024.'
    },
  ],
  '15-251: Great Theoretical Ideas in Computer Science': [
    {
      name: 'Turing Machine Validator',
      href: '/theoretical-cs#turing-machine-validator',
      highlight: false,
      imageSrc: '/assets/images/turing-machine.png', // Placeholder, update with actual path
      description: 'Description of the Turing Machine Validator project.'
    },
  ],
  '18-095: Mathematical Foundations for the Information Age': [
    {
      name: 'Hamerschlag Hall Directory',
      href: '/math-foundations#hamerschlag-hall-directory',
      highlight: false,
      imageSrc: '/assets/images/hamerschlag-directory.png', // Placeholder, update with actual path
      description: 'Interactive directory project for Hamerschlag Hall.'
    },
    {
      name: 'Photo Capturer with AI Analysis',
      href: '/math-foundations#photo-capturer-ai',
      highlight: false,
      imageSrc: '/assets/images/photo-capturer-ai.png', // Placeholder, update with actual path
      description: 'A project combining photography with AI-based analysis.'
    },
  ],
};

export const getHighlightedProjects = (categories) => {
  let highlightedProjects = [];
  Object.values(categories).forEach(category =>
    category.forEach(project => {
      if (project.highlight) {
        highlightedProjects.push(project);
      }
    })
  );
  return highlightedProjects;
};

const ProjectsPage = () => {
  const [expandedCategories, setExpandedCategories] = useState({});
  const [areAllExpanded, setAreAllExpanded] = useState(true); // New state to track expand/collapse all

  // Initialize all categories as expanded
  useEffect(() => {
    expandAllCategories(true); // Initially expand all categories
  }, []);

  const expandAllCategories = (expand) => {
    const allCategoriesExpanded = Object.keys(categoriesData).reduce((acc, category) => {
      acc[category] = expand; // Set all to expand or collapse based on the parameter
      return acc;
    }, {});
    setExpandedCategories(allCategoriesExpanded);
    setAreAllExpanded(expand); // Update the tracking state
  };

  const toggleCategory = (category) => {
    setExpandedCategories(prev => {
        const updatedCategories = {
            ...prev,
            [category]: !prev[category]
        };

        // Check if all categories are now either expanded or collapsed
        const allExpanded = Object.values(updatedCategories).every(value => value);
        const allCollapsed = Object.values(updatedCategories).every(value => !value);

        if (allExpanded) {
            setAreAllExpanded(true);
        } else if (allCollapsed) {
            setAreAllExpanded(false); // This additional logic handles the all collapsed scenario.
        } else {
            setAreAllExpanded(true)
        }

        return updatedCategories;
    });
  };

  return (
    <main className="max-w-6xl mx-auto p-5">
      <section id="projects" className="space-y-4">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Projects</h2> {/* Adjusted margin and text color */}
        <div className="flex justify-center mb-6"> {/* Centered button with adjusted spacing */}
          <button
            onClick={() => expandAllCategories(!areAllExpanded)}
            className="bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-800 transition duration-300"
          > {/* Updated colors for better visual integration */}
            {areAllExpanded ? 'Collapse All' : 'Expand All'}
          </button>
        </div>
        {Object.entries(categoriesData).map(([categoryName, projects]) => (
          <div key={categoryName} className="category mb-5"> {/* Adjusted margin between categories */}
            <button
              onClick={() => toggleCategory(categoryName)}
              className="text-left w-full bg-indigo-500 text-white p-2 rounded mb-3 focus:outline-none hover:bg-indigo-700 transition duration-300"
            > {/* Updated button colors to match the scheme */}
              <h3 className="hover:underline">{categoryName}</h3>
            </button>
            {expandedCategories[categoryName] && (
              <div className="grid grid-cols-3 gap-4 p-4 bg-white rounded shadow-lg">
                {projects.map((project) => (
                  <Project
                    key={project.name}
                    name={project.name}
                    href={project.href}
                    imageSrc={project.imageSrc}
                    description={project.description}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </section>
    </main>
  );
};

export default ProjectsPage;
