import React from 'react';

const Experience = ({ title, company, period, description, imageSrc }) => {
  return (
    <div className="mb-6 p-4 bg-white shadow-md rounded-lg flex">
      <img src={imageSrc} alt={`${company} Logo`} className="w-20 h-20 object-cover mr-4 rounded" />
      <div>
        {/* Display company name above the title */}
        <h3 className="text-xl font-semibold">{company}</h3>
        <p className="text-gray-500 text-lg">{title}</p> {/* Use p for title to differentiate from company */}
        <p className="text-gray-600">{period}</p>
        <p className="mt-2 text-gray-800">{description}</p>
      </div>
    </div>
  );
};

export default Experience;
