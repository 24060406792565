import React from 'react';

const Project = ({ name, href, imageSrc, description }) => {
  return (
    <div className="border p-4 rounded-lg shadow hover:shadow-md transition duration-300 flex flex-col">
      <div className="flex flex-row items-start space-x-4">
        <img src={imageSrc} alt={name} className="w-16 h-16 object-cover rounded-lg" />
        <div className="flex-1">
          <a href={href} className="text-xl font-bold hover:text-blue-600 transition duration-300">
            {name}
          </a>
        </div>
      </div>
      <p className="mt-2 text-gray-600">{description}</p>
    </div>
  );
};

export default Project;
