import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import headshot from './assets/images/headshot.png';
import { getHighlightedProjects, categoriesData } from './ProjectsPage';
import Project from './Project';
import { experiencesData } from './ExperiencePage';
import Experience from './Experience';

const Section = ({ children, title }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const props = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(100px)',
  });

  return (
    <div className="my-12"> {/* Maintain separation between sections */}
      <animated.div ref={ref} style={props}>
        <h2 className="text-3xl font-bold text-blue-800 mb-8 text-center">{title}</h2>
        <div className="flex justify-center items-center">
          <div className="w-full">{children}</div>
        </div>
      </animated.div>
    </div>
  );
};

const HomePage = () => {
  const highlightedProjects = getHighlightedProjects(categoriesData);
  const highlightedExperiences = experiencesData.slice(0, 3);
  const highlightedHackathons = categoriesData['Hackathons']

  return (
    <div>
      <section className="text-center py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <img
            src={headshot}
            alt="Anthony Bustamante"
            className="mx-auto rounded-full w-32 h-32 mb-8 border-4 border-blue-500"
          />
          <h1 className="text-4xl font-bold">Hello, I'm Anthony Bustamante</h1>
          <p className="mt-4 text-lg">A sophomore studying Computer Science at Carnegie Mellon, passionate about software development, entrepreneurship, and innovation.</p>
        </div>
      </section>

      <Section title="Experience">
        <div className="mx-auto px-4" style={{ maxWidth: '1200px' }}>
          {highlightedExperiences.map((experience) => (
            <Experience 
              key={experience.id}
              title={experience.title} 
              company={experience.company}
              period={experience.period}
              description={experience.description} 
              imageSrc={experience.imageSrc}
            />
          ))}
        </div>
      </Section>

      <Section title="Project Highlights">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center items-center mx-auto px-4" style={{ maxWidth: '1200px' }}>
          {highlightedProjects.map((project) => (
            <Project 
              key={project.name} 
              name={project.name} 
              href={project.href} 
              imageSrc={project.imageSrc}
              description={project.description}
            />
          ))}
        </div>
      </Section>

      <Section title="Hackathon Highlights">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center items-center mx-auto px-4" style={{ maxWidth: '1200px' }}>
          {highlightedHackathons.map((hack) => (
            <Project 
              key={hack.name} 
              name={hack.name} 
              href={hack.href} 
              imageSrc={hack.imageSrc}
              description={hack.description}
            />
          ))}
        </div>
      </Section>

      <Section title="Connect with Me">
        <div className="text-center"> {/* Reduced top margin */}
          <div className="flex justify-center items-center space-x-4 max-w-2xl mx-auto">
            <a href="https://www.linkedin.com/in/russellbustamante" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">LinkedIn</a>
            <a href="https://twitter.com/anthonybustama" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Twitter</a>
            <a href="https://github.com/russellbustamante" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">GitHub</a>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default HomePage;
